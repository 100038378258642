<template>
	<div class="article__view">
		<div class="header">
			<h2 v-if="$route.name == 'articleEdit'">#{{article.id}} <span>- Modification de l'article</span></h2>
			<h2 v-else>Rédiger un article</h2>
			<v-btn v-if="$route.name == 'articleEdit'" color="white elevation-0" :loading="loadingDelete" class="white--text" @click="dialog = true; toDelete = article" icon >
				<v-icon color="red lighten-1">
					mdi-trash-can-outline
				</v-icon>				
			</v-btn>
			<v-dialog v-model="dialog" width="300">
				<v-card>
					<v-card-text style="padding-top: 20px">
						<h3 class="text-center">Supprimer l'article de Maax ?</h3>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" text @click="dialog = false">
							Annuler
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn v-if="site.user > 0" color="red darken-3" :loading="loadingDelete" text @click="deleteArticle()">
							Supprimer
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="control">
			<v-btn v-if="site.user > 0" color="#272727" class="white--text" @click="retour()" tile x-large>
				Retour
			</v-btn>
			<v-btn v-if="$route.name == 'articleEdit' && article.id > 0 && article.status != 'publish'" color="green" class="white--text" :loading="loading" @click="updateArticle()" tile x-large>
				Sauvegarder
			</v-btn>
			<v-btn v-else-if="$route.name == 'articleAdd' && site.id > 0 && article.status != 'publish'" color="green" class="white--text" :loading="loading" @click="addArticle()" tile x-large >
				Sauvegarder
			</v-btn>
		</div>
		<div class="displayer">
			<div class="left" v-if="$route.name == 'articleEdit'">
				<infos :article="article"></infos>
			</div>
			<div class="right">
				<div class="article__card">
					<div class="article">
						<div v-if="$route.name == 'articleEdit'">
							<h3 v-if="article.status == 'publish'">Article publié sur le site</h3>
							<h3 v-else-if="article.status == 'draft'">Article non publié sur le site</h3>
							<br>
						</div>
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
						>
							<div v-if="article.status == 'publish'">
								<b>Titre</b>
								<p>{{ article.titre }}</p>
								<br>
								<b>Contenu</b>
								<p>{{ article.content }}</p>
								<br>
							</div>
							<div v-else>
								<v-text-field v-model="article.titre" :rules="titreRules" label="Titre"></v-text-field>
								<v-textarea v-model="article.content" :rules="contentRules" label="Contenu"></v-textarea>
							</div>
							<div v-if="article.file > 0">
								<v-img :src="urlImage"></v-img>
								<v-btn v-if="article.status != 'publish'" color="red darken-3" :loading="loadingDeleteImage" class="white--text" @click="deleteImageLocal()" block>Supprimer l'image</v-btn>
							</div>
							<div v-else class="upload__image">
								<div v-if="article.status != 'publish'" class="displayer">
									<div class="upload__gallery">
										<h4>Charger une image</h4>
										<v-file-input accept="image/png, image/jpeg" placeholder="Choisir une image" truncate-length="15" v-model="uploadImage" @change="uploading()" counter show-size></v-file-input>
										<v-progress-linear class="loading__file" indeterminate color="primary" v-if="loadingUpload"></v-progress-linear>
									</div>
								</div>
								<h4 v-if="urlImage != ''">Aperçu de la photo de l'article {{ urlImage }}</h4>
								<v-img v-if="urlImage != ''" :src="urlImage"></v-img>
							</div>
						</v-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { utils } from '../utils.js'
import infos from '../components/article/infos.vue'

export default {
	name: 'Article',
	components: {
		infos
	},
	data() {
		return {
			valid: false,
			titreRules: [
				v => !!v || 'Champs requis'
			],
			contentRules: [
				v => !!v || 'Champs requis'
			],
			article: {
				id: 0,
				titre: '',
				content: '',
				status: 'draft',
				updated: '',
				created: '',
				url: '',
				file: null
			},
			site: {
				id: 0,
				user: 0
			},
			urlImage: '', // l'url qui sert uniquement à l'affichage (si l'article possède une image ou non)
			idImageTempo: 0, // ID qui définit si l'article possède une image ou non mais aussi l'ID qui est envoyé pour delete ou add l'image
			uploadImage: null, // Le file envoyé pour l'upload d'image
			
			loadingDeleteImage: false,
			toDelete: {},
			dialog: false,
			loading: false,
			loadingDelete: false,
			loadingUpload: false
		}
	},
	watch: {
		uploadImage: function() {
			if (this.uploadImage == null) {
				this.urlImage = ''
				return
			}
			this.urlImage = URL.createObjectURL(this.uploadImage)
		}
	},
	methods: {
		...mapActions("user", [
			"getUserAction"
		]),
		...mapActions("global", [
			"displayFileAction",
			"addFileAction",
			"deleteFileAction",
			"getArticleAction",
			"addArticleAction",
			"updateArticleAction",
			"deleteArticleAction"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
		retour() {
			this.deleteImage()
			this.$router.push({ path: '/user/' + this.site.user });
		},
		deleteImageLocal() {
			this.article.file = null
			this.urlImage = '';
		},
		deleteImage() {
			if (this.idImageTempo < 1) {
				return
			}

			const _this = this;
			this.deleteFileAction(this.idImageTempo)
			.finally(() => {
				_this.loadingUpload = false;
				this.idImageTempo = 0;
			})
		},
		uploading() {
			const _this = this;
			this.loadingUpload = true;
			this.deleteImage()

			this.addFileAction({
				user: this.site.user,
				uploadImage: this.uploadImage
			})
			.then(res => {
				_this.article.file = res.data.id
				_this.idImageTempo = res.data.id
			})
			.finally(() => {
				_this.loadingUpload = false;
			})
		},
		updateArticle() {
			if (this.$refs.form.validate() == false) {
				return
			}

			const _this = this;
			this.loading = true;
			this.updateArticleAction({
				article: this.article.id,
				titre: this.article.titre,
				status: this.article.status,
				content: this.article.content,
				file: this.article.file
			})
			.then(() => {
				_this.idImageTempo = 0
				_this.addSuccess("L'article a été modifié.")
				_this.retour()
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		addArticle() {
			if (this.$refs.form.validate() == false) {
				return
			}
			
			const _this = this;
			this.loading = true;
			this.addArticleAction({
				titre: this.article.titre,
				status: this.article.status,
				content: this.article.content,
				site: this.site.id,
				file: this.article.file
			})
			.then((res) => {
				_this.idImageTempo = 0
				
				if (res.data.status == 'draft') {
					_this.addSuccess("L'article a été ajouté en prépublication sur Maax.")
				} else {
					_this.addSuccess("L'article a été publié directement sur le site.")
				}

				_this.retour()
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		deleteArticle() {
			const _this = this;
			this.loadingDelete = true;
			this.deleteArticleAction(this.toDelete.id)
			.then(() => {
				_this.addSuccess("L'article a été supprimé de Maax.")
				_this.retour()
				_this.loadingDelete = false
				_this.toDelete = {}
				_this.dialog = false;
			})
		},
		loadArticle() {
			const _this = this
			this.getArticleAction(this.$route.params.articleId)
			.then((res) => {
				_this.article.id = res.data.id
				_this.article.titre = res.data.titre
				_this.article.content = res.data.content
				_this.article.created = res.data.created
				_this.article.updated = res.data.updated
				_this.article.url = res.data.url
				_this.article.status = res.data.status
				_this.site.id = res.data.site.id
				_this.site.user = res.data.site.user
				_this.article.file = res.data.file
				_this.loadFile()
			})
		},
		loadFile() {
			if (this.article.file < 1) {
				return
			}

			const _this = this
			this.displayFileAction(this.article.file)
			.then((res) => {
				_this.urlImage = res
			})
		},
		loadUser() {
			const _this = this
			this.getUserAction(this.$route.params.userId)
			.then(function (res) {
				_this.site.user = res.data.id
				_this.site.id = res.data.site.id
			})
		}
	},
	created() {
		if (this.$route.name === 'articleEdit') {
			this.loadArticle()
		} else if (this.$route.name === 'articleAdd') {
			this.loadUser()
		}
	}
}
</script>

<style lang="scss" scoped>
.article__view {
	min-height: 100vh;

	.header {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dbdbdb;

		h2 {
			font-weight: normal;
			margin-bottom: 20px;
			width: 100%;
			margin: 0;

			span {
				font-size: 16px;
				color: #969696;
			}
		}
	}

	.control {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 100;

		.v-btn {
			width: 50%;
		}
	}

	.displayer {
		display: flex;
		height: 100%;

		.left {
			width: 25%;
			min-height: 100vh;
			border-right: 1px solid #dbdbdb;
		}

		.right {
			width: 75%;
			padding: 1% 2% 5% 2%;
			height: fit-content;

			.article__card {
				padding: 10px;

				h4 {
					color: #c1c1c1;
					font-weight: normal;
				}

				.article {
					margin-top: 2%;

					.swicth__status {
						display: flex;
						align-items: center;

						h3 {
							margin-right: 10px;
						}
					}

					.info__image {
						margin-top: 15px;
					}

					.upload__image {
						margin-top: 25px;
						.displayer {
							display: flex;
							gap: 50px;
						}

						.grid__gallery {
							width: 50%;
							border-right: 2px dashed #c7c7c7;
							min-height: 250px;

							.row {
								padding: 10px;
							}

							.gallery {
								cursor: pointer;
								position: relative;

								.selected {
									position: absolute;
									top: 0;
									right: 0;
									border-radius: 50%;
									background: green;
									z-index: 2;
									width: 25px;
									height: 25px;
									display: flex;
									align-items: center;
									justify-content: center;

									i {
										font-size: 15px;
									}
								}
							}
						}

						.upload__gallery {
							width: 50%;
						}

						.loading__file {
							margin-top: 50px;
						}
					}

				}
			}
		}
	}
}
</style>